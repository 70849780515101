<template>
  <div class="base-chart">
    <canvas v-show="isShowing" ref="chart" :height="height"></canvas>
    <div v-if="!isShowing" class="base-chart-empty">
      <slot name="empty" />
    </div>
  </div>
</template>
<script>
import { merge } from 'lodash';
import { Chart } from 'chart.js';
import { ChartColors, defaultLineChartData } from '@/constants';

export default {
  props: {
    height: {
      type: Number,
      default: null
    },
    chartData: {
      type: Object,
      default: () => defaultLineChartData
    },
    options: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      chart: null,
      chartType: null,
      chartOptions: this.options,
      datasetOptions: this.datasetOptions,
      toRegister: [],
      showChart: true
    };
  },
  computed: {
    isShowing() {
      return this.showChart || !this.$slots.empty;
    },
    config() {
      return {
        type: this.chartType,
        data: this.mapDatasetOptions(this.chartData),
        options: this.chartOptions
      };
    }
  },
  watch: {
    options: {
      deep: true,
      handler() {
        // If the config has changed, lets be sure to update
        this.chart.options = merge(this.chart.options, this.options);

        this.chart.update();
      }
    },
    chartData: {
      deep: true,
      handler() {
        // If the config has changed, lets be sure to update
        this.chart.data = this.mapDatasetOptions(this.chartData);
        this.chart.update();
      }
    }
  },
  created() {
    if (!this.chartType) {
      throw Error(
        'You must set chartType in the extending component of BaseChart'
      );
    }

    Chart.register(...this.toRegister);
  },
  mounted() {
    this.chart = new Chart(this.$refs.chart, this.config);
  },
  methods: {
    mapDatasetOptions(data) {
      if (data.datasets) {
        for (let [index, dataset] of data.datasets.entries()) {
          this.applyDatasetOptions(index, dataset);
        }
      }

      return data;
    },
    applyDatasetOptions(index, dataset) {
      if (this.chart) {
        switch (this.chart.type) {
          case 'doughnut':
            dataset.backgroundColor = ChartColors;
            break;

          case 'line':
          case 'bar':
          default:
            dataset.backgroundColor = ChartColors[index % ChartColors.length];
            break;
        }
      }
    },
    updateOptions(options) {
      this.chartOptions = merge(this.chartOptions, options);
      this.chart.options = this.chartOptions;
      this.chart.update();
    },
    updateData(data) {
      this.$emit('data', data);
      this.chart.data = this.mapDatasetOptions(data);
      this.chart.update();
    }
  }
};
</script>
